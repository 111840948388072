// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("/Users/lee/Dropbox/Design/Personal/leeallen.net/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/Users/lee/Dropbox/Design/Personal/leeallen.net/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/lee/Dropbox/Design/Personal/leeallen.net/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-style-guide-js": () => import("/Users/lee/Dropbox/Design/Personal/leeallen.net/repo/src/pages/style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */),
  "component---src-pages-word-js": () => import("/Users/lee/Dropbox/Design/Personal/leeallen.net/repo/src/pages/word.js" /* webpackChunkName: "component---src-pages-word-js" */),
  "component---src-pages-work-js": () => import("/Users/lee/Dropbox/Design/Personal/leeallen.net/repo/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

exports.data = () => import("/Users/lee/Dropbox/Design/Personal/leeallen.net/repo/.cache/data.json")

